<template>
    <div>
      <Home_navbar HeaderClass="fixed-top"></Home_navbar>
      <!-- Breadcrumbs section starts here  -->
      <section class="breadcrumbs">
        <div class="container">   
          <div class="d-flex justify-content-between align-items-center">
             <h2>Admin | Dashboard</h2>
            <ol>
              <li><router-link to="/">Home</router-link></li>
              <li>Admin</li>
            </ol>
          </div>  
        </div>
      </section>

      <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container">
          <div class="row">
            <!-- The main article section starte here -->
            <div class="col-lg-4 my-5 offset-2">
              <article class="entry entry-single">
                <!-- Portfolio Search Box & Add Button-->
                <b-alert show variant="danger" v-if="danger">Incorrect User Name/Password!</b-alert>
                <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                      <b-form-group
                        id="input-group-1"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          v-model="form.username"
                          placeholder="User name"
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group id="input-group-2"  label-for="input-2">
                        <b-form-input
                          id="input-2"
                          v-model="form.password"
                          placeholder="Password"
                          required
                        ></b-form-input>
                      </b-form-group>
                      
                      <b-button pill type="submit" variant="primary">Login</b-button>
                      <b-button pill type="reset" variant="danger">Reset</b-button>
                      <b-button v-b-modal.modal-prevent-closing pill type="submit" variant="success">Register</b-button>
                      
                </b-form>
              </article>

    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Create Account"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="NewUserName"
            :state="nameState"
            required
          ></b-form-input>
          
        </b-form-group>
        <b-form-group
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="NewUserName"
            :state="nameState"
            required
          ></b-form-input>
          
        </b-form-group>        
        <b-form-group
          label="pswd"
          label-for="pswd-input"
          invalid-feedback="Password is required"
          :state="pswdState"
        >
          <b-form-input
            id="pswd-input"
            v-model="NewUserPswd"
            :state="pswdState"
            required
          ></b-form-input>
        </b-form-group>        
      </form>
    </b-modal>              
                        
       
            </div>
            <!-- Article Right side bar Ends here -->
          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->        

      <!-- ======= Inject Footer section here  ======= -->
      <Home_footer/>
    
    </div><!-- End Main Div  -->
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

import {fb} from '../firebase'

Vue.use(Vueaxios,axios)
  export default {
    data() {
      return {
        form: {
          username: '',
          password: '',
        },     
        show: true,
        danger: false,
        NewUserName: '',
        NewUserPswd: '',
        nameState: null,  
        pswdState: null,      
      }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        // this.GetAdminUser()
        // this.ValidateAdminUser()
        this.login()
        // this.$router.replace({ name: "admin-dashboard" });   
      },        
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.username = ''
        this.form.password = ''
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.danger=false
        this.$nextTick(() => {
        this.show = true
        })
      },
      login(){
        fb.auth().signInWithEmailAndPassword(this.form.username, this.form.password)
          .then(() => {
            this.$router.push('admin');  
          })
          .catch(function(error) {
              // Handle Errors here.
              var errorCode = error.code;
              var errorMessage = error.message;
              if (errorCode === 'auth/wrong-password') {
                  alert('Wrong password.');
              } else {
                  alert(errorMessage);
              }
              console.log(error);
        });
      },
      register(){
        fb.auth().createUserWithEmailAndPassword(this.NewUserName, this.NewUserPswd)
          .then(function() {
              alert("Successfully created user account!")
              })
          .catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          if (errorCode == 'auth/weak-password') {
            alert('The password is too weak.');
          } else {
            alert(errorMessage);
          }
          console.log(error);
        });
      },
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.nameState = valid
        return valid
      },
      resetModal() {
        this.NewUserName = ''
        this.NewUserPswd = ''
        this.nameState = null
        this.pswdState = null
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        this.register()
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
      }          
    },
    computed: { 
  }    
  }
</script>

